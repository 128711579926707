<template>
  <div class="viewDashBoard">
    <div class="container">
      <div
        v-if="
          typeof getActiveUserData.user !== 'undefined' &&
            Object.keys(getActiveUserData.user).length
        "
      >
        <admin-projects-status-category-vue
          v-if="(getActiveUserData.userRoles == 'admin') || (getActiveUserData.userRoles == 'subadmin')"
        ></admin-projects-status-category-vue>
      </div>
      <div v-else class="white-row loader-route">
        <div class="lds-sub-ripple">
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import adminvue from "./create/admin-create-status-cat";

export default {
  components: {
    "admin-projects-status-category-vue": adminvue
  },
  computed: {
    getActiveUserData() {
      return this.$store.state.auth;
    }
  },
  data() {
    return {};
  },
  mounted() {
    document.title = this.$router.app._route.meta.pageTitle;
  },
  methods: {}
};
</script>
